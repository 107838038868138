import React, { useState, useEffect } from 'react';
import { db } from '../../../../firebase.js';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import Select from "react-select";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { all } from 'axios';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const PayrollitemForm = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [nonCashBenefitFormData, setNonCashBenefitFormData] = useState({
        nonCashBenefitName: '',
        nonCashBenefitAmount: 0,
        employeeName: [],
        organisationId: 0,
    });

    const [housingBenefitFormData, setHousingBenefitFormData] = useState({
        typeOfHousing: '',
        rentOfHouseMarketValue: 0,
        rentRecoveredFromEmployee: 0,
        employeeName: [],
        organisationId: 0,
    });

    const [cashBenefitFormData, setCashBenefitFormData] = useState({
        cashBenefitName: '',
        cashBenefitAmount: 0,
        permissibleLimit: 20000,
        employeeName: [],
        organisationId: 0,
    });

    const [allowanceFormData, setAllowanceFormData] = useState({
        allowanceName: '',
        allowanceAmount: 0,
        employeeName: [],
        organisationId: 0,
    });

    const [otherDeductionFormData, setOtherDeductionFormData] = useState({
        deductionName: '',
        deductionAmount: 0,
        employeeName: [],
        organisationId: 0,
    });


    const [employees, setEmployees] = useState([]);
    const [salaries, setSalaries] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const customStyles = {
        control: base => ({
            ...base,
            height: 43,
            minHeight: 43
        })
    };

    const showAdditionalFields = cashBenefitFormData.cashBenefitName === 'pensionBenefits';
    
    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const handleNonCashBenefitInputChange = (e) => {
        const { name, value } = e.target;

        const isNumericField = ['nonCashBenefitAmount'].includes(name);
        const numericValue = isNumericField ? Number(value): value;
        setNonCashBenefitFormData({
            ...nonCashBenefitFormData,
            [name]: numericValue,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const handleHousingBenefitInputChange = (e) => {
        const { name, value } = e.target;

        const isNumericField = ['rentOfHouseMarketValue', 'rentRecoveredFromEmployee'].includes(name);

        const numericValue = isNumericField ? Number(value): value;
        setHousingBenefitFormData({
            ...housingBenefitFormData,
            [name]: numericValue,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const handleCashBenefitInputChange = (e) => {
        const { name, value } = e.target;
        const isNumericField = ['cashBenefitAmount'].includes(name);
        const numericValue = isNumericField ? Number(value): value;
        setCashBenefitFormData({
            ...cashBenefitFormData,
            [name]: numericValue,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const handleAllowanceInputChange = (e) => {
        const { name, value } = e.target;
        const isNumericField = ['allowanceAmount'].includes(name);
        const numericValue = isNumericField ? Number(value): value;
        setAllowanceFormData({
            ...allowanceFormData,
            [name]: numericValue,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const handleOtherDeductionInputChange = (e) => {
        const { name, value } = e.target;
        const isNumericField = ['deductionAmount'].includes(name);
        const numericValue = isNumericField ? Number(value): value;
        setOtherDeductionFormData({
            ...otherDeductionFormData,
            [name]: numericValue,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };
    // const handleOtherDeductionInputChange = (e) => {
    //     const { name, value } = e.target;

    //     // Define a regular expression pattern to match invalid inputs
    //     const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

    //     // Check if the field is the deductionName field
    //     if (name === 'deductionName') {
    //         // If it is the deductionName field, check for invalid characters
    //         // if (invalidPattern.test(value)) {
    //         //     // If it contains invalid characters, don't update the state and show an error message
    //         //     showToast('Invalid input: Special characters and symbols are not allowed', 'error');
    //         //     return;
    //         // }
    //     }

    //     // Update the state for other fields
    //     setOtherDeductionFormData({
    //         ...otherDeductionFormData,
    //         [name]: value,
    //     });

    //     if (name === 'companyName') {
    //         const company = companies.find(c => c.companyName === value);
    //         setSelectedCompany(company);
    //     }
    // };


    useEffect(() => {
        const fetchSalaries = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-salary'));
            const salaryList = [];

            querySnapshot.forEach((doc) => {
                const salaryData = doc.data();
                if (!salaryData.deleted && salaryData.organisationId === userData[0]?.organisationId) {
                    salaryList.push({ id: doc.id, ...salaryData });
                }
            });

            setSalaries(salaryList);
        };

        fetchSalaries();
    }, [userData, nonCashBenefitFormData.employeeName, housingBenefitFormData.employeeName, cashBenefitFormData.employeeName, allowanceFormData.employeeName, otherDeductionFormData.employeeName]);

    const nonCashRequiredFields =
        [
            'nonCashBenefitName',
            'nonCashBenefitAmount',
            'employeeName'
        ];

    const handleNonCashBenefitSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(nonCashBenefitFormData).every(value => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check if any options are selected in the employeeName field
        if (nonCashBenefitFormData.employeeName.length === 0) {
            showToast('Employees cannot be empty', 'error');
            return;
        }

        // If the form is not empty, check each field for empty value
        const errorMessages = [];
        Object.entries(nonCashBenefitFormData).forEach(([key, value]) => {
            if (nonCashRequiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
                console.log(key);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        const existingNonCashQuery = query(collection(db, 'payroll-noncash-benefit'), where('employeeName', '==', nonCashBenefitFormData.employeeName), where('nonCashBenefitName', '==', nonCashBenefitFormData.nonCashBenefitName));
        const existingNonCashSnapshot = await getDocs(existingNonCashQuery);

        if (!existingNonCashSnapshot.empty) {
            showToast('This non cash benefit already exists for this employee', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'payroll-noncash-benefit'), {
            //     ...nonCashBenefitFormData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'payroll-noncash-benefit'), {
                    ...nonCashBenefitFormData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'payroll-noncash-benefit'), {
                    ...nonCashBenefitFormData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Non Cash Benefit Added Successfully', 'success');

            setNonCashBenefitFormData = ({
                nonCashBenefitName: '',
                nonCashBenefitAmount: 0,
                employeeName: [],
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const housingRequiredFields =
        [
            'typeOfHousing',
            'rentOfHouseMarketValue',
            'rentRecoveredFromEmployee',
            'employeeName',
        ];

    const handleHousingBenefitSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(housingBenefitFormData).every(value => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check if any options are selected in the employeeName field
        if (housingBenefitFormData.employeeName.length === 0) {
            showToast('Employees cannot be empty', 'error');
            return;
        }

        const errorMessages = [];
        Object.entries(housingBenefitFormData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (housingRequiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        const existingHousingQuery = query(collection(db, 'payroll-housing-benefit'), where('employeeName', '==', housingBenefitFormData.employeeName));
        const existingHousingSnapshot = await getDocs(existingHousingQuery);

        if (!existingHousingSnapshot.empty) {
            showToast('Housing benefit already exists for this employee', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'payroll-housing-benefit'), {
            //     ...housingBenefitFormData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'payroll-housing-benefit'), {
                    ...housingBenefitFormData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'payroll-other-deduction'), {
                    ...housingBenefitFormData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Housing Benefit Added Successfully', 'success');

            setHousingBenefitFormData = ({
                typeOfHousing: '',
                rentOfHouseMarketValue: 0,
                rentRecoveredFromEmployee: 0,
                employeeName: [],
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const cashRequiredFields =
        [
            'cashBenefitName',
            'cashBenefitAmount',
            'employeeName'
        ];

    const handleCashBenefitSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(cashBenefitFormData).every(value => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check if any options are selected in the employeeName field
        if (cashBenefitFormData.employeeName.length === 0) {
            showToast('Employees cannot be empty', 'error');
            return;
        }

        // If the form is not empty, check each field for empty value
        const errorMessages = [];
        Object.entries(cashBenefitFormData)
            .filter(key => !['pensionCashPay', 'permissibleLimit', ].includes(key))
            .forEach(([key, value]) => {
            if (cashRequiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
                console.log(key);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        const existingCashQuery = query(collection(db, 'payroll-cash-benefit'), where('employeeName', '==', cashBenefitFormData.employeeName), where('cashBenefitName', '==', cashBenefitFormData.cashBenefitName));
        const existingCashSnapshot = await getDocs(existingCashQuery);

        if (!existingCashSnapshot.empty) {
            showToast('This cash benefit already exists for this employee', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'payroll-cash-benefit'), {
            //     ...cashBenefitFormData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'payroll-cash-benefit'), {
                    ...cashBenefitFormData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'payroll-cash-benefit'), {
                    ...cashBenefitFormData,
                    organisationId: selectedCompany?.organisationId || null,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Cash Benefit Added Successfully', 'success');

            setCashBenefitFormData({
                cashBenefitName: '',
                cashBenefitAmount: 0,
                // pensionCashPay: 0,
                permissibleLimit: 20000,
                employeeName: [],
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const allowanceRequiredFields =
        [
            'allowanceName',
            'allowanceAmount',
            'employeeName',
        ];

    const handleAllowanceSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(allowanceFormData).every(value => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check if the employeeName field is empty (i.e., no options are selected)
        if (allowanceFormData.employeeName.length === 0) {
            showToast('Employees cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(allowanceFormData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (allowanceRequiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        const existingAllowanceQuery = query(collection(db, 'payroll-allowance'), where('employeeName', '==', allowanceFormData.employeeName), where('allowanceName', '==', allowanceFormData.allowanceName));
        const existingAllowanceSnapshot = await getDocs(existingAllowanceQuery);

        if (!existingAllowanceSnapshot.empty) {
            showToast('This allowance already exists for this employee', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'payroll-allowance'), {
            //     ...allowanceFormData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'payroll-allowance'), {
                    ...allowanceFormData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'payroll-other-deduction'), {
                    ...allowanceFormData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Allowance Added Successfully', 'success');

            setAllowanceFormData({
                allowanceName: '',
                allowanceAmount: 0,
                employeeName: [],
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const otherDeductionRequiredFields =
        [
            'deductionName',
            'deductionAmount',
            'employeeName',
        ];

    const handleOtherDeductionSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(otherDeductionFormData).every(value => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check if any options are selected in the employeeName field
        if (otherDeductionFormData.employeeName.length === 0) {
            showToast('Employees cannot be empty', 'error');
            return;
        }

        // If the form is not empty, check each field for empty value
        const errorMessages = [];
        Object.entries(otherDeductionFormData).forEach(([key, value]) => {
            if (otherDeductionRequiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
                console.log(key);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        const existingDeductionQuery = query(collection(db, 'payroll-other-deduction'), where('employeeName', '==', otherDeductionFormData.employeeName), where('deductionName', '==', otherDeductionFormData.deductionName));
        const existingDeductionSnapshot = await getDocs(existingDeductionQuery);

        if (!existingDeductionSnapshot.empty) {
            showToast('This deduction already exists for this employee', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'payroll-other-deduction'), {
            //     ...otherDeductionFormData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'payroll-other-deduction'), {
                    ...otherDeductionFormData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'payroll-other-deduction'), {
                    ...otherDeductionFormData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Other Deduction Added Successfully', 'success');

            setOtherDeductionFormData({
                deductionName: '',
                deductionAmount: 0,
                employeeName: [],
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' && 
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();
    }, [userData, selectedCompany]);

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    return (
        <>
            {/* Add Non Cash Benefit Modal */}
            <div
                id="add_non_cash_benefit"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Non Cash Benefit</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleNonCashBenefitSubmit} noValidate>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Non Cash Benefit Name <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="nonCashBenefitName"
                                                value={nonCashBenefitFormData.nonCashBenefitName}
                                                onChange={handleNonCashBenefitInputChange}
                                                required
                                            >
                                                <option value>Select Non-Cash Benefit</option>
                                                <option value='Car Benefit'>Car Benefit</option>
                                                <option value='Other'>Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Amount <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nonCashBenefitAmount"
                                                value={nonCashBenefitFormData.nonCashBenefitAmount}
                                                onChange={handleNonCashBenefitInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={nonCashBenefitFormData.companyName}
                                                onChange={handleNonCashBenefitInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>Employees <span className="text-danger">*</span></label>
                                            {/* <Select
                                                isMulti
                                                name="employeeName"
                                                options={employees.map(employee => ({
                                                    value: employee.employeeName,
                                                    label: employee.employeeName
                                                }))}
                                                value={nonCashBenefitFormData.employeeName.map(selected => ({
                                                    value: selected,
                                                    label: selected
                                                }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setNonCashBenefitFormData({
                                                        ...nonCashBenefitFormData,
                                                        employeeName: selectedValues
                                                    });
                                                }}
                                                required
                                            /> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="employeeName"
                                                autoComplete="off"
                                                value={nonCashBenefitFormData.employeeName.join(", ")}
                                                onChange={(e) => {
                                                    const values = e.target.value.split(",").map((v) => v.trim());
                                                    setNonCashBenefitFormData({ ...nonCashBenefitFormData, employeeName: values });
                                                }}
                                                placeholder="Enter names separated by commas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Non Cash Benefit Modal */}

            {/* Add Housing Benefit Modal */}
            <div
                id="add_housing_benefit"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Housing Benefit</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleHousingBenefitSubmit} noValidate>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Type of Housing <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="typeOfHousing"
                                                value={housingBenefitFormData.typeOfHousing}
                                                onChange={handleHousingBenefitInputChange}
                                                required
                                            >
                                                <option value>Select Type of Housing</option>
                                                <option value='Employees Owned House'>Employer's Owned House</option>
                                                <option value='Employees Rented House'>Employer's Rented House</option>
                                                <option value='Agriculture'>Agriculture</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>
                                                Rent of House / Market Value <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="rentOfHouseMarketValue"
                                                value={housingBenefitFormData.rentOfHouseMarketValue}
                                                onChange={handleHousingBenefitInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>
                                                Rent Recovered from Employee <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="rentRecoveredFromEmployee"
                                                value={housingBenefitFormData.rentRecoveredFromEmployee}
                                                onChange={handleHousingBenefitInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={housingBenefitFormData.companyName}
                                                onChange={handleHousingBenefitInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>Employees<span className="text-danger">*</span></label>
                                            {/* <Select
                                                isMulti
                                                name="employeeName"
                                                styles={customStyles}
                                                options={employees.map(employee => ({
                                                    value: employee.employeeName,
                                                    label: employee.employeeName
                                                }))}
                                                value={housingBenefitFormData.employeeName.map(selected => ({
                                                    value: selected,
                                                    label: selected
                                                }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setHousingBenefitFormData({
                                                        ...housingBenefitFormData,
                                                        employeeName: selectedValues
                                                    });
                                                }}
                                                required
                                            /> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="employeeName"
                                                autoComplete="off"
                                                value={housingBenefitFormData.employeeName.join(", ")}
                                                onChange={(e) => {
                                                    const values = e.target.value.split(",").map((v) => v.trim());
                                                    setHousingBenefitFormData({ ...housingBenefitFormData, employeeName: values });
                                                }}
                                                placeholder="Enter names separated by commas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Housing Benefit Modal */}

            {/* Add Cash Benefit Modal */}
            <div
                id="add_cash_benefit"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Cash Benefit</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleCashBenefitSubmit} noValidate>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>
                                                Cash Benefit Name <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="cashBenefitName"
                                                value={cashBenefitFormData.cashBenefitName}
                                                onChange={handleCashBenefitInputChange}
                                                required
                                            >
                                                <option value>Select Cash Benefit</option>
                                                <option value='Pension Benefits'>Pension Benefits</option>
                                                <option value='Mortgage Interest'>Mortgage Interest</option>
                                                {/* <option value='Deposit On Home Ownership'>Deposit on Home Ownership</option> */}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>
                                                Cash Benefit Amount <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="cashBenefitAmount"
                                                value={cashBenefitFormData.cashBenefitAmount}
                                                onChange={handleCashBenefitInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={cashBenefitFormData.companyName}
                                                onChange={handleCashBenefitInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>Employees <span className="text-danger">*</span></label>
                                            {/* <Select
                                                isMulti
                                                name="employeeName"
                                                styles={customStyles}
                                                options={employees.map(employee => ({
                                                    value: employee.employeeName,
                                                    label: employee.employeeName
                                                }))}
                                                value={cashBenefitFormData.employeeName.map(selected => ({
                                                    value: selected,
                                                    label: selected
                                                }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setCashBenefitFormData({
                                                        ...cashBenefitFormData,
                                                        employeeName: selectedValues
                                                    });
                                                }}
                                                required
                                            /> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="employeeName"
                                                autoComplete="off"
                                                value={cashBenefitFormData.employeeName.join(", ")}
                                                onChange={(e) => {
                                                    const values = e.target.value.split(",").map((v) => v.trim());
                                                    setCashBenefitFormData({ ...cashBenefitFormData, employeeName: values });
                                                }}
                                                placeholder="Enter names separated by commas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Cash Benefit Modal */}

            {/* Add Allowance Modal */}
            <div
                id="add_allowance"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Allowance</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleAllowanceSubmit} noValidate>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Allowance Name <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="allowanceName"
                                                value={allowanceFormData.allowanceName}
                                                onChange={handleAllowanceInputChange}
                                                required
                                            >

                                                <option value>Select Allowance</option>
                                                <option value='Housing Allowance'>Housing</option>
                                                <option value='Transport Allowance'>Transport</option>
                                                <option value='Leave Pay'>Leave Pay</option>
                                                <option value='Overtime Allowance'>Overtime Allowance</option>
                                                <option value='Directors Fee'>Director's Fee</option>
                                                <option value='Lump Sum Payment'>Lump Sum Payment</option>
                                                <option value='Other Allowance'>Other Allowance</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Allowance Amount <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="allowanceAmount"
                                                value={allowanceFormData.allowanceAmount}
                                                onChange={handleAllowanceInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={allowanceFormData.companyName}
                                                onChange={handleAllowanceInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>Employees<span className="text-danger">*</span></label>
                                            {/* <Select
                                                isMulti
                                                name="employeeName"
                                                styles={customStyles}
                                                options={employees.map(employee => ({
                                                    value: employee.employeeName,
                                                    label: employee.employeeName
                                                }))}
                                                value={allowanceFormData.employeeName.map(selected => ({
                                                    value: selected,
                                                    label: selected
                                                }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setAllowanceFormData({
                                                        ...allowanceFormData,
                                                        employeeName: selectedValues
                                                    });
                                                }}
                                                required
                                            /> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="employeeName"
                                                autoComplete="off"
                                                value={allowanceFormData.employeeName.join(", ")}
                                                onChange={(e) => {
                                                    const values = e.target.value.split(",").map((v) => v.trim());
                                                    setAllowanceFormData({ ...allowanceFormData, employeeName: values });
                                                }}
                                                placeholder="Enter names separated by commas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal"
                                            type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Allowance Modal */}

            {/* Add Other Deduction Modal */}
            <div
                id="add_other_deduction"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Other Deduction</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleOtherDeductionSubmit} noValidate>
                                <div className="col-md-12">
                                    <div className="input-block">
                                        <label>
                                            Deduction Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="deductionName"
                                            value={otherDeductionFormData.deductionName}
                                            onChange={handleOtherDeductionInputChange}
                                            required
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="input-block">
                                        <label>
                                            Amount <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="deductionAmount"
                                            value={otherDeductionFormData.deductionAmount}
                                            onChange={handleOtherDeductionInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={otherDeductionFormData.companyName}
                                                onChange={handleOtherDeductionInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                <div className="col-sm-12">
                                    <div className="input-block">
                                        <label>Employees<span className="text-danger">*</span></label>
                                        {/* <Select
                                            isMulti
                                            name="employeeName"
                                            options={employees.map(employee => ({
                                                value: employee.employeeName,
                                                label: employee.employeeName
                                            }))}
                                            value={otherDeductionFormData.employeeName.map(selected => ({
                                                value: selected,
                                                label: selected
                                            }))}
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                setOtherDeductionFormData({...otherDeductionFormData, employeeName: selectedValues});
                                            }}
                                            required
                                        /> */}
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="employeeName"
                                            autoComplete="off"
                                            value={otherDeductionFormData.employeeName.join(", ")}
                                            onChange={(e) => {
                                                const values = e.target.value.split(",").map((v) => v.trim());
                                                setOtherDeductionFormData({ ...otherDeductionFormData, employeeName: values });
                                            }}
                                            placeholder="Enter names separated by commas"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal"
                                            type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Other Deduction Modal */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default PayrollitemForm;

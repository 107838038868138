import React, { useContext, useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Header from "../common/Header.js";
import Sidebar from "../common/Sidebar.js";
import {Link} from "react-router-dom";
import EmployeeProfileForm from "./employeeProfileForm.js";
import { UserContext } from "../../InitialPage/App.js";
import { ToastContainer, toast, Slide } from "react-toastify";
import {Avatar_02} from "../../Entryfile/imagepath";

const auth = getAuth();

const EmployeeProfile = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [menu, setMenu] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);


    const formatDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        return new Date(year, month - 1, day).toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
    };

    const getGenderLabel = (gender) => {
        return gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : 'Other';
    };
    const getMaritalStatus = (maritalStatus) => {
        return maritalStatus === 'M' ? 'Married' : maritalStatus === 'S' ? 'Single' : maritalStatus === 'D' ? 'Divorced' : 'Other';
    };

    const getRole = (role) => {
        return role === 'sys-admin' ? 'System Admin' : role === 'admin' ? 'Company Admin' : role === 'hod' ? 'Head of Deparment' : role === 'finance' ? 'Finance' : role === 'employee' ? 'Employee' : role === 'hr' ? 'HR' : 'User';
    };

    return (
        <>
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <div className="app-container">
                <Header onMenuClick={() => toggleMobileMenu()}/>
                <div className="main-content">
                    <Sidebar />
                    <div className="page-wrapper">
                        <div className="content container-fluid">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="page-title">Profile</h3>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Profile</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="profile-view">
                                            {!loading && userData.length > 0 && (
                                                <div className="profile-img-wrap">
                                                {userData.length > 0 && userData.map(user => (
                                                    <div className="profile-img">
                                                        <Link to="#">
                                                            <img alt="img" src={user.selfieURL || Avatar_02}  />
                                                        </Link>
                                                    </div>
                                                ))}
                                                </div>
                                            )}
                                            {!loading && userData.length > 0 && (
                                                <div className="profile-basic">
                                                {userData.length > 0 && userData.map(user => (
                                                    <div className="row" key={user.id}>
                                                        <div className="col-md-5">
                                                            <div className="profile-info-left">
                                                                <h3 className="user-name m-t-0 mb-0">
                                                                   {/* {user.employeeName ? (
                                                                        user.employeeName
                                                                    ) : (
                                                                        `${user.firstName} ${user.lastName}`
                                                                    )} */}
                                                                    {user.firstName ? (
                                                                        `${user.firstName} ${user.lastName}`
                                                                    ) : (
                                                                        user.employeeName
                                                                    )}
                                                                </h3>
                                                                

                                                                <small className="text-muted">{getRole(user.role) || "Edit to update"}</small>< br/>
                                                                <div className="text-muted">
                                                                    {user.dateOfBirth ? (
                                                                        formatDate(user.dateOfBirth)
                                                                    ) : (
                                                                        "Date of birth not available"
                                                                    )}
                                                                </div>
                                                                <div className="text-muted">{getGenderLabel(user.gender) || "Edit to update"}</div>
                                                                < br/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <ul className="personal-info">
                                                                <li>
                                                                    <div className="title">Phone:</div>
                                                                    <div className="text">
                                                                        <Link to="#">+{user.phone || "Edit to update"}</Link>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="title">Email:</div>
                                                                    <div className="text">
                                                                        <Link to="#">
                                                                           {user.email || "Edit to update"}
                                                                        </Link>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="title">Address:</div>
                                                                    <div className="text">
                                                                        {user.address?.line1 || "Edit to update"}, {user.address?.city ||"Edit to update"}, {user.address?.country || "Edit to update"}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
                                                </div>
                                            )}
                                                <div className="pro-edit">
                                                    <Link
                                                        data-bs-target="#profile_info"
                                                        data-bs-toggle="modal"
                                                        className="edit-icon"
                                                        to="#">
                                                        <i className="fa fa-pencil" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!loading && userData.length > 0 && (
                            <div className="tab-content">

                                {/* Profile Info Tab */}
                                {userData.length > 0 && userData.map(user => (
                                <div
                                    id="emp_profile"
                                    className="pro-overview tab-pane fade show active">
                                    <div className="row">
                                        <div className="col-md-6 d-flex">
                                            <div className="card profile-box flex-fill">
                                                <div className="card-body">
                                                    <h3 className="card-title">
                                                        Personal Information{" "}
                                                        <Link
                                                            to="#"
                                                            className="edit-icon"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#personal_info_modal">
                                                            <i className="fa fa-pencil" />
                                                        </Link>
                                                    </h3>
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Id No.</div>
                                                            <div className="text">{user.nationalIdentityNumber}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Nationality</div>
                                                            <div className="text">{user.birthCountry || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Marital status </div>
                                                            <div className="text">{getMaritalStatus(user.maritalStatus) || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Employment of spouse</div>
                                                            <div className="text">{user.employmentOfSpouse || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">No. of children</div>
                                                            <div className="text">{user.noOfChildren || "Edit to update"}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-flex">
                                            <div className="card profile-box flex-fill">
                                                <div className="card-body">
                                                    <h3 className="card-title">
                                                        Emergency Contact{" "}
                                                        <Link
                                                            to="#"
                                                            className="edit-icon"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#emergency_contact_modal">
                                                            <i className="fa fa-pencil" />
                                                        </Link>
                                                    </h3>
                                                    <h5 className="section-title">Primary</h5>
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Name</div>
                                                            <div className="text">{user.familyMembers?.familyName1 || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Relationship</div>
                                                            <div className="text">{user.familyMembers?.relationship1 || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Phone </div>
                                                            <div className="text">{user.familyMembers?.familyPhone1 || "Edit to update"}</div>
                                                        </li>
                                                    </ul>
                                                    <hr />
                                                    <h5 className="section-title">Secondary</h5>
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Name</div>
                                                            <div className="text">{user.familyMembers?.familyName2 || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Relationship</div>
                                                            <div className="text">{user.familyMembers?.relationship2 || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Phone </div>
                                                            <div className="text">{user.familyMembers?.familyPhone2 || "Edit to update"}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 d-flex">
                                            <div className="card profile-box flex-fill">
                                                <div className="card-body">
                                                    <h3 className="card-title">
                                                        Bank Information{" "}
                                                        <Link
                                                            to="#"
                                                            className="edit-icon"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#wallet_modal">
                                                            <i className="fa fa-pencil" />
                                                        </Link>
                                                    </h3>
                                                    
                                                    <p className="text-primary text-small">
                                                        Do you want a DTB wallet? <Link to="/register">register for one here</Link>
                                                    </p>
                                                    {/* <br/> */}
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Bank Name</div>
                                                            <div className="text">{user.bank?.bankName || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Account Number</div>
                                                            <div className="text">{user.bank?.accountNumber || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Account Name</div>
                                                            <div className="text">{user.bank?.accountName || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Bank Branch</div>
                                                            <div className="text">{user.bank?.bankBranch || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">M-Pesa Number</div>
                                                            <div className="text">{user.bank?.mpesa || "Edit to update"}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            )}
                        </div>

                        <EmployeeProfileForm/>
                    </div>
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                    />
            </div>
        </div>
        </>
    )
}

export default EmployeeProfile;

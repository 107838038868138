import React, { useEffect, useState } from "react";
import { db } from "../../firebase.js";
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import {Avatar_02} from "../../Entryfile/imagepath";
import {countries} from "countries-list";
import { ToastContainer, toast, Slide } from "react-toastify";
import { banks } from "../../lib/astra.js";
import DatePicker from "react-datepicker";

const auth = getAuth();

const CompanyProfileForm = () => {
    const currentUser = auth.currentUser;
    const storage = getStorage();
    const [logoFile, setLogoFile] = useState(null);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    
    const [countryNames, setCountryNames] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const fetchCountryNames = async () => {
            const countryNamesArray = Object.values(countries).map(
                (country) => country.name
            );

            if (isMounted) {
                setCountryNames(countryNamesArray);
            }
        };

        fetchCountryNames();

        return () => {
            isMounted = false;
        };
    }, []);

    const [formData, setFormData] = useState({
        logoURL: '',
        phone: '',
        email: '',
        tradingName: '',
        industrialSector: '',
        businessRegistrationDate: '',
        companyNumber: '',
        businessType: '',
        address:{
            addressType: '',
            country: '',
            city: '',
            line1: '',
            line2: '',
            state: '',
        },
        bank: {
            bankName: '',
            accountNumber: '',
            accountName: '',
            bankBranch: '',
            mpesa: '',
        }
    });

    const [selectedDate, setSelectedDate] = useState(null);
    
    const handleDateChange = (date) => {
        if (date) {
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            setSelectedDate(date);
            setFormData(prevData => ({
                ...prevData,
                businessRegistrationDate: formattedDate
            }));
        }
    };
    
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setLogoFile(file);

        // Read the image file and convert it to a URL
        const reader = new FileReader();
        reader.onload = () => {
            setFormData(prevData => ({
                ...prevData,
                logoURL: reader.result
            }));
        };
        reader.readAsDataURL(file);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddressInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            address: {
                ...formData.address,
                [name]: value,
            },
        });
    };


    const handleBankInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            bank: {
                ...formData.bank,
                [name]: value,
            },
        });
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (currentUser && currentUser.email) {
                    const userEmail = currentUser.email;
                    // console.log('Fetching data for user email:', userEmail);

                    const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", userEmail)));

                    if (!querySnapshot.empty) {
                        // Assuming there's only one document with the given email
                        const userData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
                        // setFormData(userData);
                        setFormData(prevData => ({
                            ...prevData,
                            ...userData
                        }));
                        // console.log('Fetched data:', userData);
                    } else {
                        console.log('No document found for user email:', userEmail);
                    }
                } else {
                    console.log('No current user or email provided.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentUser && currentUser.email) {
                const userEmail = currentUser.email;
                // console.log('Fetching data for user email:', userEmail);

                const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", userEmail)));
                const querySnapshot2 = await getDocs(query(collection(db, "add-company"), where("email", "==", userEmail)));

                if (!querySnapshot.empty) {
                    // Assuming there's only one document with the given email
                    const userId = querySnapshot.docs[0].id;
                    const userId2 = querySnapshot2.docs[0].id;

                    // Update the document with the edited form data
                    const colRef = doc(db, 'users', userId);
                    const colRef2 = doc(db, 'add-company', userId2);

                    if (selectedDate) {
                        const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
                        formData.businessRegistrationDate = formattedDate;
                    }
                    
                    if (logoFile) {
                        const storageRef = ref(storage, `logos/${currentUser.uid}/${logoFile.name}`);
                        await uploadBytes(storageRef, logoFile);
                        const downloadURL = await getDownloadURL(storageRef);
                        formData.logoURL = downloadURL;
                    }

                    await updateDoc(colRef, formData);
                    await updateDoc(colRef2, formData);

                    toast.success('Profile updated successfully.');
                } else {
                    console.log('No document found for user email:', userEmail);
                }

            } else {
                console.log('No current user or email provided.');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <>
        {formData && Object.keys(formData).length > 0 && (
            <div
                id="company_info"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Company Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="profile-img-wrap edit-img">
                                            <img
                                                className="inline-block"
                                                src={formData.logoURL || Avatar_02}
                                                // alt="user"
                                            />
                                            <div className="fileupload btn">
                                                <span className="btn-text">edit</span>
                                                <input className="upload" type="file"/>
                                                <input
                                                    className="upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleLogoChange}
                                                />

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Industrial Sector</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="industrialSector"
                                                        autoComplete="off"
                                                        value={formData.industrialSector}
                                                        onChange={handleInputChange}
                                                        placeholder="eg. Tech, Finance..."
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Registration Date</label>
                                                    <div className="cal-icon">
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            onChange={handleDateChange}
                                                            className="form-control datetimepicker"
                                                            type="date"
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        autoComplete="off"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="phone"
                                                        autoComplete="off"
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}

            {formData && Object.keys(formData).length > 0 && (
            <div
                id="company_info_modal"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Company Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Company Number</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="companyNumber"
                                                autoComplete="off"
                                                value={formData.companyNumber}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Trading Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="tradingName"
                                                autoComplete="off"
                                                value={formData.tradingName}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Industrial Sector </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="industrialSector"
                                                autoComplete="off"
                                                value={formData.industrialSector}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Business Type </label>
                                            <select
                                                className="form-control form-select"
                                                name="businessType"
                                                autoComplete="off"
                                                value={formData.businessType}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">--</option>
                                                <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
                                                <option value="LIMITED_COMPANY">Limited Company</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {formData && Object.keys(formData).length > 0 && (
            <div
                id="address_information_modal"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Address Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title"></h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Address Type{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control form-select"
                                                        name="addressType"
                                                        autoComplete="off"
                                                        value={formData.address.addressType}
                                                        onChange={handleAddressInputChange}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="BRANCH">BRANCH</option>
                                                        <option value="HEADQUARTERS">HEADQUARTERS</option>
                                                        <option value="OPERATING">OPERATING</option>
                                                        <option value="PHYSICAL">PHYSICAL</option>
                                                        <option value="POSTAL">POSTAL</option>
                                                        <option value="PRIMARY">PRIMARY</option>
                                                        <option value="REGISTERED">REGISTERED</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Country <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-select form-control"
                                                        name="country"
                                                        required
                                                        value={formData.address.country}
                                                        onChange={handleAddressInputChange}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {countryNames.sort().map((country) => (
                                                            <option key={country} value={country}>
                                                                {country}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        City <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        autoComplete="off"
                                                        value={formData.address.city}
                                                        onChange={handleAddressInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title"></h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Line 1 <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="line1"
                                                        autoComplete="off"
                                                        value={formData.address.line1}
                                                        onChange={handleAddressInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Line 2 <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="line2"
                                                        autoComplete="off"
                                                        value={formData.address.line2}
                                                        onChange={handleAddressInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        State <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        autoComplete="off"
                                                        value={formData.address.state}
                                                        onChange={handleAddressInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* Bank Info Modal */}
            {formData && Object.keys(formData).length > 0 && (
            <div
                id="wallet_modal"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Bank Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Bank Name
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="bankName"
                                                autoComplete="off"
                                                value={formData.bank.bankName}
                                                onChange={handleBankInputChange}
                                            >
                                                <option value="">-</option>
                                                {banks.length > 0 &&
                                                    banks
                                                    .sort((a, b) =>
                                                        a.bankName.localeCompare(b.bankName)
                                                    )
                                                    .map((bank, i) => (
                                                        <option value={bank.bankName} key={i}>
                                                        {bank.bankName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Account Number</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="accountNumber"
                                                autoComplete="off"
                                                value={formData.bank.accountNumber}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Account Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="accountName"
                                                autoComplete="off"
                                                value={formData.bank.accountName}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Bank Branch</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="bankBranch"
                                                autoComplete="off"
                                                value={formData.bank.bankBranch}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>M-Pesa Number</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="mpesa"
                                                autoComplete="off"
                                                value={formData.bank.mpesa}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* Bank Info Modal */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default CompanyProfileForm;

import React, {useContext, useState, useEffect} from "react";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { db } from '../../../../firebase.js';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import {UserContext} from "../../../../InitialPage/App.js";
import {Applogo} from "../../../../Entryfile/imagepath.js";
import html2pdf from 'html2pdf.js';
import { format } from 'date-fns';
import numberToWords from 'number-to-words';
import {toast, ToastContainer, Slide} from "react-toastify";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const GeneratedP9Form =  () => {
    const { userRole } = useContext(UserContext);

    const [userData, setUserData] = useState([]);
    const { ids } = useParams();
    const { year } = useParams();

    const selectedEmployeeIds = ids.split(',');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [employeeDataList, setEmployeeDataList] = useState([]);

    const [employeeData, setEmployeeData] = useState(null);
    const [qualification, setQualification] = useState(null);
    const [kraPinNo, setkraPinNo] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [netSalaryInWords, setNetSalaryInWords] = useState('');
    const [menu, setMenu] = useState(false);
    const [formattedMonth, setFormattedMonth] = useState(null);
    const [reports, setReports] = useState({});
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [logo, setLogo] = useState([]);
    
        useEffect(() => {
            const fetchLogo = async () => {
                if (userData && userData[0]?.role !== 'sys-admin') {
                    const logoSnapshot = await getDocs(
                        query(collection(db, 'add-company'), where('organisationId', '==', userData[0]?.organisationId))
                    );
                    const logoData = logoSnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(item => !item.deleted);
                    setLogo(logoData);
                }
            };
    
            fetchLogo();
        }, [userData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const employeeDataArray = [];

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin'){
                    if (!userData || userData.length === 0 || !userData[0].organisationId) {
                        console.error("User data is not available or incomplete.", userData);
                        // setError("User data is not available or incomplete.");
                        setLoading(false);
                        return;
                    }

                    const organisationId = userData[0].organisationId;
                    console.log("Using organisationId:", organisationId);
                }
                // Fetch data for selected employee IDs from add-employee collection
                const promises = selectedEmployeeIds.map(async id => {
                    try {
                        // Fetch employee data from add-employee collection
                        const employeeDocRef = doc(db, 'add-employee', id);
                        const employeeDocSnapshot = await getDoc(employeeDocRef);

                        if (employeeDocSnapshot.exists()) {
                            const employeeData = employeeDocSnapshot.data();
                            employeeData.id = id;
    
                            const currentYear = new Date().getFullYear().toString(); 
                            // Fetch salary data from add-salary collection based on employeeName
                            const salaryQuery = query(
                                collection(db, 'add-salary'), 
                                where('employeeName', '==', employeeData.employeeName),
                                where('month', '>=', `${currentYear}-01`),
                                where('month', '<=', `${currentYear}-12`),
                            );
                            const salaryQuerySnapshot = await getDocs(salaryQuery);
                            // Fetch company data based on organisationId
                            const companyQuery = query(
                                collection(db, 'add-company'),
                                where('organisationId', '==', employeeData.organisationId)
                            );
                            const companyDocs = await getDocs(companyQuery);
                            const companyDataArray = companyDocs.docs.map(doc => doc.data());
    
                            if (companyDataArray.length > 0) {
                                employeeData.companyData = companyDataArray;
                            }
                                
                            // For each salary record found
                            salaryQuerySnapshot.docs.forEach(salaryDoc => {
                                const salaryData = salaryDoc.data();
                                const employeeWithSalary = {
                                    ...employeeData,
                                    month: salaryData.month,
                                    basicSalary: salaryData.basicSalary,
                                    pensionCashPay: salaryData.pensionCashPay,
                                    permissibleLimit: salaryData.permissibleLimit,
                                    rentOfHouseComputed: salaryData.rentOfHouseComputed,
                                    netValueOfHousing: salaryData.netValueOfHousing,
                                    housingLevy: salaryData.housingLevy,
                                    personalRelief: salaryData.personalRelief,
                                    insuranceRelief: salaryData.insuranceRelief,
                                    currency: salaryData.currency,
                                    totalGrossPay: salaryData.totalGrossPay,
                                    netPay: salaryData.netPay,
                                    payePayable: salaryData.payePayable,
                                    nssfContribution: salaryData.nssfContribution,
                                    nhifContribution: salaryData.nhifContribution,
                                    taxablePay: salaryData.taxablePay,
                                    taxPayable: salaryData.taxPayable,
                                    totalCashPay: salaryData.totalCashPay,
                                    totalNonCashPay: salaryData.totalNonCashPay,
                                    totalAmountOfBenefit: salaryData.totalAmountOfBenefit,
                                    companyData: companyDataArray,
                                    salaryId: salaryDoc.id // Store salary document ID for reference
                                };
                                employeeDataArray.push(employeeWithSalary);
                            });
                        } else {
                            // Fetch salary data from add-salary collection based on ID
                            const salaryDocRef = doc(db, 'add-salary', id);
                            const salaryDocSnapshot = await getDoc(salaryDocRef);

                            if (salaryDocSnapshot.exists()) {
                                const salaryData = salaryDocSnapshot.data();
                                salaryData.id = id;

                                const currentYear = new Date().getFullYear().toString();
                                
                                const otherSalariesQuery = query(
                                    collection(db, 'add-salary'),
                                    where('employeeName', '==', salaryData.employeeName),
                                    where('month', '>=', `${currentYear}-01`),
                                    where('month', '<=', `${currentYear}-12`),
                                );
                                const otherSalariesSnapshot = await getDocs(otherSalariesQuery);
                                
                                const companyQuery = query(
                                    collection(db, 'add-company'),
                                    where('organisationId', '==', salaryData.organisationId)
                                );
                                const companyDocs = await getDocs(companyQuery);
                                const companyDataArray = companyDocs.docs.map(doc => doc.data());
                                    
                                if (companyDataArray.length > 0) {
                                    salaryData.companyData = companyDataArray;
                                }

                                otherSalariesSnapshot.docs.forEach(salaryDoc => {
                                    const salaryData = salaryDoc.data();
                                    const allSalaries = {
                                        month: salaryData.month,
                                        basicSalary: salaryData.basicSalary,
                                        pensionCashPay: salaryData.pensionCashPay,
                                        permissibleLimit: salaryData.permissibleLimit,
                                        rentOfHouseComputed: salaryData.rentOfHouseComputed,
                                        netValueOfHousing: salaryData.netValueOfHousing,
                                        housingLevy: salaryData.housingLevy,
                                        personalRelief: salaryData.personalRelief,
                                        insuranceRelief: salaryData.insuranceRelief,
                                        currency: salaryData.currency,
                                        totalGrossPay: salaryData.totalGrossPay,
                                        netPay: salaryData.netPay,
                                        payePayable: salaryData.payePayable,
                                        nssfContribution: salaryData.nssfContribution,
                                        nhifContribution: salaryData.nhifContribution,
                                        taxablePay: salaryData.taxablePay,
                                        taxPayable: salaryData.taxPayable,
                                        totalCashPay: salaryData.totalCashPay,
                                        totalNonCashPay: salaryData.totalNonCashPay,
                                        totalAmountOfBenefit: salaryData.totalAmountOfBenefit,
                                        companyData: companyDataArray,
                                        salaryId: salaryDoc.id 
                                    };
                                    employeeDataArray.push(allSalaries);
                                });
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        throw error;
                    }
                });
                await Promise.all(promises);
                employeeDataArray.sort((a, b) => new Date(a.month) - new Date(b.month));
                setEmployeeDataList(employeeDataArray);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
    
        fetchData();
        setLoading(false);
    }, [userData, selectedEmployeeIds]);    


    const handlePDFExport = () => {
        const element = document.getElementById('p9-container');
    
        html2pdf()
            .from(element)
            .set({
                pagebreak: { mode: 'avoid-all' }, 
                filename: 'p9-form.pdf',
                margin: [10, 10, 10, 10],
                jsPDF: { format: 'a3', orientation: 'landscape' }, // Use a3 for more space
                html2canvas: { scale: 2 } // Increase scale for better quality
            })
            .save();
    };
    

    useEffect(() => {
        // Convert net salary to words when employeeDataList or any of its elements change
        if (employeeDataList.length > 0 && employeeDataList[0]?.netPay) {
            const netSalaryInWords = numberToWords.toWords(employeeDataList[0].netPay);
            setNetSalaryInWords(netSalaryInWords);
        }
    }, [employeeDataList]);

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('p9-container').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const generateCSV = () => {
        const csvRows = [];

        // Header row
        const header = [
            'Employee Name',
            'Month',
            'Basic Salary (Kshs)',
            'Benefits Non Cash (Kshs)',
            'Total Gross Pay (Kshs)',
            'Defined Contribution Retirement Scheme (Kshs)',
            'Chargeable Pay (Kshs)',
            'Tax Charged (Kshs)',
            'Personal Relief (Kshs)',
            'Insurance Relief (Kshs)',
            'PAYE Tax (J-K) (Kshs)'
        ];
        csvRows.push(header.join(','));

        // Data rows
        employeeDataList.forEach(employeeData => {
            const row = [
                employeeData.employeeName,
                format(new Date(employeeData.month), 'MMMM'),
                employeeData.basicSalary || '',
                employeeData.totalNonCashPay || '',
                employeeData.totalGrossPay || '',
                employeeData.pensionCashPay || '',
                employeeData.taxablePay || '',
                employeeData.taxPayable || '',
                employeeData.personalRelief || '',
                employeeData.insuranceRelief || '',
                employeeData.payePayable || ''
            ];
            csvRows.push(row.join(','));
        });

        // Join rows with newline character
        const csvString = csvRows.join('\n');

        // Create a Blob object and initiate download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'p9-form.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const formattedYear = format(selectedDate, 'yyyy');

    const handleEmailSend = () => { //(recipientEmail)
        const p9Container = document.getElementById('p9-container');
        
        if (!p9Container) {
            console.error('P9 container not found.');
            showToast('Error: P9 container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(p9Container)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "P9 form for " + format(new Date(), 'MMMM yyyy'),
                        Body: "Please find attached the P9 form for this month.",
                        Attachments: [
                            {
                                name: "p9-form.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('P9 form sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending p9 form via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">P9 Form</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`/finance/payroll/downloads`}>Downloads</Link>
                                                 </li>
                                                <li className="breadcrumb-item active">P9 Form</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                {/* <button className="btn btn-white" onClick={handleEmailSend}><i className="fa fa-mail-bulk fa-lg"/> Email</button> */}
                                                <button className="btn btn-white" onClick={generateCSV}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                {/* <button className="btn btn-white">CSV</button> */}
                                                <button className="btn btn-white" onClick={handlePDFExport}><i className="fa fa-file-pdf fa-lg"/> PDF</button>
                                                <button className="btn btn-white" onClick={handlePrint}><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {error && <div>Error: {error}</div>}
                                {!error && (
                                <div className="row" id="p9-container">
                                    {/* Group data by employee and render */}
                                    {(() => {
                                        // Group data by employee
                                        const employeeGroups = {};
                                        employeeDataList.forEach(data => {
                                            if (!employeeGroups[data.employeeName]) {
                                                employeeGroups[data.employeeName] = {
                                                    employeeInfo: {
                                                        id: data.id,
                                                        employeeName: data.employeeName,
                                                        kraPinNo: data.kraPinNo,
                                                        companyData: data.companyData,
                                                        qualification: data.qualification,
                                                        email: data.email
                                                    },
                                                    salaryData: []
                                                };
                                            }
                                            employeeGroups[data.employeeName].salaryData.push(data);
                                        });
                                    // Convert groups to array for rendering
                                    return Object.values(employeeGroups).map((group, empIndex) => (
                                    <div key={empIndex} className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">
                                                    Tax Deduction Card for the Year{" "}
                                                    {group.salaryData.length > 0 && (
                                                        <strong>{new Date(group.salaryData[0].month).getFullYear()}</strong>
                                                    )}
                                                </h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        {logo[0]?.logoURL ? (
                                                            <img src={logo[0].logoURL} className="inv-logo" alt=""/>
                                                        ) : group.employeeInfo.companyData?.length > 0 ? (
                                                            group.employeeInfo.companyData.map((company, index) => (
                                                                <img key={index} src={company.logoURL} className="inv-logo" alt=""/>
                                                            ))
                                                        ) : (
                                                            <img src={Applogo} className="inv-logo" alt=""/>
                                                        )}
                                                        {group.employeeInfo.companyData && group.employeeInfo.companyData.map(company => (
                                                            <React.Fragment key={company.id}>
                                                                <p>Employer: <strong>{company.companyName}</strong></p>
                                                            </React.Fragment>
                                                        ))}
                                                        <p>Name: <strong>{group.employeeInfo.employeeName}</strong></p>
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">P9 Form</h3>

                                                            <div className="p9-text">
                                                                {group.employeeInfo.companyData && group.employeeInfo.companyData.map(company => (
                                                                    <React.Fragment key={company.id}>
                                                                        <p>Employer PIN: <strong>{company.pinNo || company.taxNumber}</strong></p>
                                                                    </React.Fragment>
                                                                ))}
                                                                {group.employeeInfo.kraPinNo && 
                                                                    <p>PIN: <strong>{group.employeeInfo.kraPinNo}</strong></p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12" >
                                                        <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>Month</th>
                                                                    <th>
                                                                        Basic <br/>
                                                                        Salary <br/>(Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Cash <br/>
                                                                        Pay <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Non <br/>
                                                                        Cash <br/>
                                                                        Benefits <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Gross <br/>
                                                                        Pay <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th colSpan={6}>
                                                                        Benefits <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    {/* <th>
                                                                        Total <br/>
                                                                        Amount of <br/>
                                                                        Benefit <br/>
                                                                        (Kshs)
                                                                    </th> */}
                                                                    <th>
                                                                        Taxable <br/>
                                                                        Pay (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Tax <br/>
                                                                        Payable <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Personal <br/>
                                                                        Relief <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Insurance <br/>
                                                                        Relief <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        PAYE <br/>
                                                                        Tax <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Net <br/>
                                                                        Pay <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    {/* <th>E</th> */}
                                                                    <th colSpan={3}>Housing</th>
                                                                    <th colSpan={2}>Pension</th>
                                                                    <th>Total <br/> Amount of <br />Benefit</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>Computed Rent</th>
                                                                    <th>Net Value</th>
                                                                    <th>Housing Levy</th>
                                                                    <th>30% of Cash Pay</th>
                                                                    <th>Permissible Limit</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {group.salaryData.map((salaryRecord, salIndex) => (
                                                                    <tr key={salIndex}>
                                                                        <td className="table-month">{format(new Date(salaryRecord.month), "MMMM")}</td>
                                                                        <td>{salaryRecord.basicSalary && new Intl.NumberFormat().format(salaryRecord.basicSalary)}</td>
                                                                        <td>{salaryRecord.totalCashPay && new Intl.NumberFormat().format(salaryRecord.totalCashPay)}</td>
                                                                        <td>{salaryRecord.totalNonCashPay && new Intl.NumberFormat().format(salaryRecord.totalNonCashPay)}</td>
                                                                        <td>{salaryRecord.totalGrossPay && new Intl.NumberFormat().format(salaryRecord.totalGrossPay)}</td>
                                                                        <td>{salaryRecord.rentOfHouseComputed && new Intl.NumberFormat().format(salaryRecord.rentOfHouseComputed)}</td>
                                                                        <td>{salaryRecord.netValueOfHousing && new Intl.NumberFormat().format(salaryRecord.netValueOfHousing)}</td>
                                                                        <td>{salaryRecord.housingLevy && new Intl.NumberFormat().format(salaryRecord.housingLevy)}</td>
                                                                        <td>{salaryRecord.pensionCashPay && new Intl.NumberFormat().format(salaryRecord.pensionCashPay)}</td>
                                                                        <td>{salaryRecord.permissibleLimit && new Intl.NumberFormat().format(salaryRecord.permissibleLimit)}</td>
                                                                        <td>{salaryRecord.totalAmountOfBenefit && new Intl.NumberFormat().format(salaryRecord.totalAmountOfBenefit)}</td>
                                                                        <td>{salaryRecord.taxablePay && new Intl.NumberFormat().format(salaryRecord.taxablePay)}</td>
                                                                        <td>{salaryRecord.taxPayable && new Intl.NumberFormat().format(salaryRecord.taxPayable)}</td>
                                                                        <td>{salaryRecord.personalRelief && new Intl.NumberFormat().format(salaryRecord.personalRelief)}</td>
                                                                        <td>{salaryRecord.insuranceRelief && new Intl.NumberFormat().format(salaryRecord.insuranceRelief)}</td>
                                                                        <td>{salaryRecord.payePayable && new Intl.NumberFormat().format(salaryRecord.payePayable)}</td>
                                                                        <td>{salaryRecord.netPay && new Intl.NumberFormat().format(salaryRecord.netPay)}</td>
                                                                    </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        ));
                                    })()}
                                </div>
                                 )}
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default GeneratedP9Form;

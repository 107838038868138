import React, { useState, useEffect, useContext } from 'react';
import Header from '../../../common/Header.js';
import Sidebar from "../../../common/Sidebar.js";
import { UserContext } from '../../../../InitialPage/App.js';
import { Link } from "react-router-dom";
import { db } from '../../../../firebase.js';
import { collection, addDoc, getDocs, where, query, serverTimestamp, orderBy, limit } from 'firebase/firestore';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const TaxRates = () => {
    const { userRole } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [data, setData] = useState([]); 
    const [entriesCount, setEntriesCount] = useState(0);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    useEffect(() => {
        const fetchTaxRates = async () => {
            try {
                setLoading(true);
                const q = query(collection(db, "tax-rates"), orderBy("version", "desc"));
                const querySnapshot = await getDocs(q);

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }));
                
                setData(dataArray);
                setEntriesCount(dataArray.length);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching latest tax rates:", error);
            }
        };    
        fetchTaxRates();
    }, []);

    return(
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Tax Rates</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Tax Rates</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="/finance/payroll/tax-rate-form"
                                                className="btn add-btn"
                                            >
                                                <i className="fa fa-plus"/> New Tax Rate
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Constants</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Housing</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>PAYE</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Common</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Other</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Version</th>
                                                        <th>Date Added</th>
                                                        <th>Personal Relief</th>
                                                        <th>Max. Insurance Relief</th>
                                                        <th>Min. NSSF</th>
                                                        <th>Max. NSSF</th>
                                                        <th>Permissible Limit</th>
                                                        <th>Rent (Owned/Rented)</th>
                                                        <th>Rent (Agriculture)</th>
                                                        <th>Net Value</th>
                                                        <th>Tier 1</th>
                                                        <th>Tier 2</th>
                                                        <th>Tier 3</th>
                                                        <th>Tier 4</th>
                                                        <th>Tier 5</th>
                                                        <th>NSSF</th>
                                                        <th>SHIF</th>
                                                        <th>Housing Levy</th>
                                                        <th>Cash Pay</th>
                                                        <th>Insurance Relief</th>
                                                        <th>Withholding Tax</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {data.slice(0, entriesPerPage).map(item => (
                                                        <tr key={item.id} >
                                                            <td>{item.version}</td>
                                                            <td>{item.timestamp ? new Date(item.timestamp.seconds * 1000).toLocaleDateString() : "N/A"}</td>
                                                            <td>{item.personalRelief || '-'}</td>
                                                            <td>{item.maxInsuranceRelief || '-'}</td>
                                                            <td>{item.minNssfContribution || '-'}</td>
                                                            <td>{item.maxNssfContribution || '-'}</td>
                                                            <td>{item.permissibleLimit || '-'}</td>
                                                            <td>{item.rentOfHouseOwnedRented || '-'}</td>
                                                            <td>{item.rentOfHouseAgriculture || '-'}</td>
                                                            <td>{item.netValueOfHousing || '-'}</td>
                                                            <td>{item.taxPayable1 || '-'}</td>
                                                            <td>{item.taxPayable2 || '-'}</td>
                                                            <td>{item.taxPayable3 || '-'}</td>
                                                            <td>{item.taxPayable4 || '-'}</td>
                                                            <td>{item.taxPayable5 || '-'}</td>
                                                            <td>{item.nssfContribution || '-'}</td>
                                                            <td>{item.nhifContribution || '-'}</td>
                                                            <td>{item.housingLevy || '-'}</td>
                                                            <td>{item.pensionCashPay || '-'}</td>
                                                            <td>{item.insuranceRelief || '-'}</td>
                                                            <td>{item.withHoldingTax || '-'}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    Showing 1 to {Math.min(entriesCount, entriesPerPage)} of {entriesCount} entries
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TaxRates;

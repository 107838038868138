import React, { useState, useContext } from 'react';
import logo2 from '../assets/img/new-rhokit-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { app, db } from '../firebase.js';
import {Link, useNavigate} from 'react-router-dom'
import { UserContext } from '../InitialPage/App.js';
import { ToastContainer, toast, Slide  } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function Login(){
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };

    const { setUserRole } = useContext(UserContext);

    const handleSubmit = async(e) => {
        e.preventDefault();

        if (!email) {
            setEmailError('Email is required');
            return;
        }

        if (!password) {
            setPasswordError('Password is required');
            return;
        }

        function getDisplayName(role) {
            switch (role) {
                case 'sys-admin':
                    return 'System Admin';
                case 'admin':
                    return 'Company Admin';
                case 'hr':
                    return 'HR';
                case 'finance':
                    return 'Finance';
                case 'hod':
                    return 'Department Head';
                case 'employee':
                    return 'Employee';
                default:
                    return role;
            }
        }

        setIsSubmitting(true);
        const auth = getAuth(app);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Fetch user data from Firestore based on email
            const q = query(collection(db, 'users'), where('email', '==', email));
            const userQuerySnapshot = await getDocs(q);

            if (!userQuerySnapshot.empty) {
                userQuerySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    const username = userData.companyName || userData.employeeName;
                    const role = userData.role;
                    setUserRole(role);
                    localStorage.setItem('userRole', role);
                    // showToast(`Welcome ${getDisplayName(role) || "User"}`, 'success');
                    showToast(`Welcome ${username}`, 'success');
                    setTimeout(() => {
                        navigate(`/dashboard/${role}`);
                    }, 1000); // Delay navigation for 1 second
                });
            } else {
                // User not found in Firestore
                showToast('User not found.', 'error');
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                showToast('Wrong password. Please try again.', 'error');
            } else if (errorCode === 'auth/invalid-email') {
                showToast('Invalid email. Please try again.', 'error');
            } else if (errorCode === 'auth/too-many-requests') {
                showToast('Too many attempts. Please try again later.', 'error');
            } else {
                showToast('An error occurred.', 'error');
            }
            console.error(errorCode, errorMessage);
        }finally {
            setIsSubmitting(false); // Set loading state to false
        }

    };

    return(
        <>
            <div className='login'>
                <div className='account-page'>
                    <div className='main-wrapper'>
                        <div className='account-content'>
                            <div className='container'>
                                <div className='account-logo'>
                                    <img src={logo2} alt="Logo" />
                                </div>

                                <div className='account-box'>
                                    <div className='account-wrapper'>
                                        <h3 className='account-title'>Login</h3>
                                        <p className='account-subtitle'>Access to our dashboard</p>

                                        <div>
                                            <form onSubmit={handleSubmit}>
                                                <div className='input-block'>
                                                    <label>Email Address</label>
                                                    <input type='text' name='email' autocomplete="off" className={`form-control ${emailError && 'is-invalid'}`} value={email} onChange={handleEmailChange} ></input>
                                                    <div className="text-danger">{emailError}</div>
                                                </div>

                                                <div className='input-block'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <label>Password</label>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <a className='text-muted' id="a" href='/reset-password'>Forgot Password?</a>
                                                        </div>
                                                    </div>

                                                    <div style={{ position: 'relative' }}>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className={`form-control ${passwordError && 'is-invalid'}`}
                                                            name='password'
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={showPassword ? faEye : faEyeSlash}
                                                            style={{ position: 'absolute', right: '5%', top: '30%', cursor: 'pointer' }}
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                    </div>
                                                    <div className="text-danger">{passwordError}</div>
                                                </div>

                                                <div className='input-block text-center'>
                                                    <button className='btn btn-primary account-btn' type='submit' disabled={isSubmitting}>
                                                        {isSubmitting ? 'loading...' : 'Login'}
                                                    </button>
                                                </div>
                                            </form>

                                            <div className='account-footer'>
                                                <p>Don't have an account yet? <Link to='/choose-receive-wallet' id="a2">Register</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>

    );
}

export default Login;

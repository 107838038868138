import React, { useState, useEffect, useContext } from 'react';
import Header from '../../../common/Header.js';
import Sidebar from "../../../common/Sidebar.js";
import { UserContext } from '../../../../InitialPage/App.js';
import { Link, useNavigate } from "react-router-dom";
import { db } from '../../../../firebase.js';
import { collection, addDoc, getDocs, where, query, serverTimestamp, orderBy, limit } from 'firebase/firestore';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const TaxRateForm = () => {
    const { userRole } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [latestData, setLatestData] = useState({});
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [formData, setFormData] = useState({
        personalRelief: '',
        maxInsuranceRelief: '',
        maxNssfContribution: '',
        permissibleLimit: '',
        rentOfHouseOwnedRented: '',
        rentOfHouseAgriculture: '',
        netValueOfHousing: '',
        pensionCashPay: '',
        nssfContribution: '',
        nhifContribution: '',
        housingLevy: '',
        taxPayable1: '',
        taxPayable2: '',
        taxPayable3: '',
        taxPayable4: '',
        taxPayable5: '',
        insuranceRelief: '',
        withHoldingTax: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        const fetchLatestTaxRates = async () => {
            try {
                const q = query(collection(db, "tax-rates"), orderBy("version", "desc"), limit(1));
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    const latestDoc = querySnapshot.docs[0];
                    setLatestData(latestDoc.data()); // Set latest tax rate values
                }
            } catch (error) {
                console.error("Error fetching latest tax rates:", error);
            }
        };

        fetchLatestTaxRates();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Query for the latest version of tax rates
            const q = query(collection(db, 'tax-rates'), orderBy('version', 'desc'), limit(1));
            const querySnapshot = await getDocs(q);
    
            let latestVersion = 0;
            let latestData = {};
    
            if (!querySnapshot.empty) {
                const latestDoc = querySnapshot.docs[0];
                latestData = latestDoc.data();
                latestVersion = latestData.version || 0;
            }
    
            // Increment version
            const newVersion = latestVersion + 1;
    
            // Ensure missing fields in formData are filled with latestData values
            const updatedData = { ...formData };
    
            Object.keys(latestData).forEach((key) => {
                if (!updatedData[key] || updatedData[key].trim() === "") {
                    updatedData[key] = latestData[key]; // Fill missing values
                }
            });
    
            // Add version and timestamp
            updatedData.version = newVersion;
            updatedData.timestamp = serverTimestamp();
    
            // Add new document
            const docRef = await addDoc(collection(db, 'tax-rates'), updatedData);
    
            console.log("Document written with ID: ", docRef.id);
            toast.success("Tax rates saved successfully");

            // setTimeout(() => {
            //     navigate("/finance/payroll/tax-rates");
            // }, 2000);
    
        } catch (error) {
            console.error("Error saving tax rates:", error);
            toast.error("Failed to save tax rates");
        }
    };
    

    return(
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Tax Rate Card</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="/finance/payroll/tax-rates"
                                                className="btn add-btn"
                                            >
                                                View all previous rates
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="tax-card">
                                            <form id="taxRateForm" onSubmit={handleSubmit} noValidate>
                                                <h3 className="text-lg mb-4 font-semibold">Constants</h3>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Personal Relief</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="personalRelief"
                                                                autoComplete="off"
                                                                value={formData.personalRelief}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.personalRelief || "Fetching..."}
                                                                style={{ color: formData.personalRelief ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Minimum NSSF Contribution</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="minNssfContribution"
                                                                autoComplete="off"
                                                                value={formData.minNssfContribution}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.minNssfContribution || "Fetching..."}
                                                                style={{ color: formData.minNssfContribution ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Maximum NSSF Contribution</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="maxNssfContribution"
                                                                autoComplete="off"
                                                                value={formData.maxNssfContribution}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.maxNssfContribution || "Fetching..."}
                                                                style={{ color: formData.maxNssfContribution ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Maximum Insurance Relief</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="maxInsuranceRelief"
                                                                autoComplete="off"
                                                                value={formData.maxInsuranceRelief}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.maxInsuranceRelief || "Fetching..."}
                                                                style={{ color: formData.maxInsuranceRelief ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Permissible Limit</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="permissibleLimit"
                                                                autoComplete="off"
                                                                value={formData.permissibleLimit}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.permissibleLimit || "Fetching..."}
                                                                style={{ color: formData.permissibleLimit ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <h3 className="text-lg mb-3 font-semibold">Housing</h3>
                                                <p className="text-primary text-xs">
                                                    Use the decimal form of the percentage eg. write 10% as 0.1
                                                </p>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Rent of house (Owned/Rented)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="rentOfHouseOwnedRented"
                                                                autoComplete="off"
                                                                value={formData.rentOfHouseOwnedRented}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.rentOfHouseOwnedRented || "Fetching..."}
                                                                style={{ color: formData.rentOfHouseOwnedRented ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Rent of house (Agriculture)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="rentOfHouseAgriculture"
                                                                autoComplete="off"
                                                                value={formData.rentOfHouseAgriculture}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.rentOfHouseAgriculture || "Fetching..."}
                                                                style={{ color: formData.rentOfHouseAgriculture ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div><div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Net Value Of Housing</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="netValueOfHousing"
                                                                autoComplete="off"
                                                                value={formData.netValueOfHousing}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.netValueOfHousing || "Fetching..."}
                                                                style={{ color: formData.netValueOfHousing ? "black" : "gray" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <h3 className="text-lg mb-3 font-semibold">PAYE</h3>
                                                <p className="text-primary text-xs">
                                                    Use the decimal form of the percentage eg. write 10% as 0.1
                                                </p>
                                                <div className='row'>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Tax Payable (tier 1)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="taxPayable1"
                                                                autoComplete="off"
                                                                value={formData.taxPayable1}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.taxPayable1 || "Fetching..."}
                                                                style={{ color: formData.taxPayable1 ? "black" : "gray" }}
                                                            /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Tax Payable (tier 2)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="taxPayable2"
                                                                autoComplete="off"
                                                                value={formData.taxPayable2}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.taxPayable2 || "Fetching..."}
                                                                style={{ color: formData.taxPayable2 ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Tax Payable (tier 3)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="taxPayable3"
                                                                autoComplete="off"
                                                                value={formData.taxPayable3}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.taxPayable3 || "Fetching..."}
                                                                style={{ color: formData.taxPayable3 ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Tax Payable (tier 4)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="taxPayable4"
                                                                autoComplete="off"
                                                                value={formData.taxPayable4}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.taxPayable4 || "Fetching..."}
                                                                style={{ color: formData.taxPayable4 ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Tax Payable (tier 5)</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="taxPayable5"
                                                                autoComplete="off"
                                                                value={formData.taxPayable5}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.taxPayable5 || "Fetching..."}
                                                                style={{ color: formData.taxPayable5 ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <h3 className="text-lg mb-3 font-semibold">Common</h3>
                                                <p className="text-primary text-xs">
                                                    Use the decimal form of the percentage eg. write 10% as 0.1
                                                </p>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>NSSF Contribution</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nssfContribution"
                                                                autoComplete="off"
                                                                value={formData.nssfContribution}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.nssfContribution || "Fetching..."}
                                                                style={{ color: formData.nssfContribution ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>SHIF Contribution</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nhifContribution"
                                                                autoComplete="off"
                                                                value={formData.nhifContribution}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.nhifContribution || "Fetching..."}
                                                                style={{ color: formData.nhifContribution ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Housing Levy</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="housingLevy"
                                                                autoComplete="off"
                                                                value={formData.housingLevy}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.housingLevy || "Fetching..."}
                                                                style={{ color: formData.housingLevy ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <h3 className="text-lg mb-3 font-semibold">Other</h3>
                                                <p className="text-primary text-xs">
                                                    Use the decimal form of the percentage eg. write 10% as 0.1
                                                </p>
                                                <div className='row'>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Pension Cash Pay</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="pensionCashPay"
                                                                autoComplete="off"
                                                                value={formData.pensionCashPay}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.pensionCashPay || "Fetching..."}
                                                                style={{ color: formData.pensionCashPay ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Insurance Relief</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="insuranceRelief"
                                                                autoComplete="off"
                                                                value={formData.insuranceRelief}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.insuranceRelief || "Fetching..."}
                                                                style={{ color: formData.insuranceRelief ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Withholding Tax</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="withHoldingTax"
                                                                autoComplete="off"
                                                                value={formData.withHoldingTax}
                                                                onChange={handleInputChange}
                                                                placeholder={latestData.withHoldingTax || "Fetching..."}
                                                                style={{ color: formData.withHoldingTax ? "black" : "gray" }}
                                                            />   
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-center mt-4">
                                                    <button className="btn btn-primary submit-btn" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default TaxRateForm;
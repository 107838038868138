import React, { useContext, useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import {Link} from "react-router-dom";
import { UserContext } from "../../InitialPage/App";
import {Avatar_02} from "../../Entryfile/imagepath";
import CompanyProfileForm from "./companyProfileForm";
import { ToastContainer, toast, Slide } from "react-toastify";


const auth = getAuth();

const CompanyProfile = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [menu, setMenu] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };


    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const formatDate = (dateString) => {
        // const year = dateString.substring(0, 4);
        // const month = dateString.substring(4, 6);
        // const day = dateString.substring(6, 8);
        let date;
        if (dateString.includes("-")) {
            // If format is YYYY-MM-DD, use Date constructor directly
            date = new Date(dateString);
        } else if (dateString.length === 8) {
            // If format is YYYYMMDD, extract parts manually
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            date = new Date(`${year}-${month}-${day}`);
        }

        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
        // return new Date(year, month - 1, day).toLocaleDateString('en-US', {
        //     day: '2-digit',
        //     month: 'long',
        //     year: 'numeric'
        // });
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar />
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="page-title">Company Profile</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Company Profile</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="profile-view">
                                                    {!loading && userData.length > 0 && (
                                                        <div className="company-profile-img-wrap">
                                                        {userData.length > 0 && userData.map(user => (
                                                            <div className="profile-img">
                                                                <Link to="#">
                                                                    <img alt="img" src={user.logoURL ? user.logoURL : Avatar_02}  />
                                                                </Link>
                                                            </div>
                                                        ))}
                                                        </div>
                                                    )}

                                                    {!loading && userData.length > 0 && (
                                                    <div className="profile-basic">
                                                        {userData.length > 0 && userData.map(user => (
                                                        <div className="row" key={user.id}>
                                                            <div className="col-md-5">
                                                                <div className="profile-info-left">
                                                                    <h3 className="user-name m-t-0 mb-0">
                                                                        {user.companyName}
                                                                    </h3>
                                                                    <small
                                                                        className="text-muted">
                                                                            {user.industrialSector || "Edit to update"}
                                                                            </small>< br/>
                                                                    <div className="text-muted">
                                                                    </div>
                                                                    <div
                                                                        className="text-muted"></div>
                                                                    < br/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <ul className="personal-info">
                                                                    <li>
                                                                        <div className="title">Phone:</div>
                                                                        <div className="text">
                                                                            <Link to="#">+{user.phone || "Edit to update"}</Link>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="title">Email:</div>
                                                                        <div className="text">
                                                                            <Link to="#">
                                                                            {user.email || "Edit to update"}
                                                                            </Link>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="title">Registration Date:</div>
                                                                        <div className="text">
                                                                            {user.businessRegistrationDate ? (
                                                                                formatDate(user.businessRegistrationDate)
                                                                            ) : (
                                                                                "Registration date not available"
                                                                            )}
                                                                            {/* {user.businessRegistrationDate || "Edit to update"} */}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    </div>
                                                )}
                                                    <div className="pro-edit">
                                                        <Link
                                                            data-bs-target="#company_info"
                                                            data-bs-toggle="modal"
                                                            className="edit-icon"
                                                            to="#">
                                                            <i className="fa fa-pencil"/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!loading && userData.length > 0 && (
                                <div className="tab-content">
                                    {/* Profile Info Tab */}
                                    {userData.length > 0 && userData.map(user => (
                                    <div
                                        id="emp_profile"
                                        className="pro-overview tab-pane fade show active">
                                        <div className="row">
                                            <div className="col-md-6 d-flex">
                                                <div className="card profile-box flex-fill">
                                                    <div className="card-body">
                                                        <h3 className="card-title">
                                                            Company Information{" "}
                                                            <Link
                                                                to="#"
                                                                className="edit-icon"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#company_info_modal">
                                                                <i className="fa fa-pencil"/>
                                                            </Link>
                                                        </h3>
                                                        <ul className="personal-info">
                                                            <li>
                                                                <div className="title">Company No.</div>
                                                                <div
                                                                    className="text">
                                                                        {user.companyNumber || "Edit to update"}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Business Type</div>
                                                                <div className="text">{user.businessType || "Edit to update"}</div>
                                                            </li>
                                                            {/* <li>
                                                                <div className="title">Industrial Classification</div>
                                                                <div
                                                                    className="text">
                                                                        {user.natureOfBusiness || "Edit to update"}
                                                                    </div>
                                                            </li> */}
                                                            <li>
                                                                <div className="title">Trading Name</div>
                                                                <div
                                                                    className="text">{user.tradingName || "Edit to update"}</div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Industrial Sector</div>
                                                                <div
                                                                    className="text">{user.industrialSector || "Edit to update"}</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 d-flex">
                                                <div className="card profile-box flex-fill">
                                                    <div className="card-body">
                                                        <h3 className="card-title">
                                                            Address Information{" "}
                                                            <Link
                                                                to="#"
                                                                className="edit-icon"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#address_information_modal">
                                                                <i className="fa fa-pencil"/>
                                                            </Link>
                                                        </h3>
                                                        <h5 className="section-title"></h5>
                                                        <ul className="personal-info">
                                                            <li>
                                                                <div className="title">Address Type</div>
                                                                <div
                                                                    className="text">{user.address?.addressType || "Edit to update"}</div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Country</div>
                                                                <div
                                                                    className="text">{user.address?.country || "Edit to update"}</div>
                                                            </li>
                                                            <li>
                                                                <div className="title">City</div>
                                                                <div
                                                                    className="text">{user.address?.city || "Edit to update"}</div>
                                                            </li>
                                                        </ul>
                                                        <hr/>
                                                        <h5 className="section-title"></h5>
                                                        <ul className="personal-info">
                                                            <li>
                                                                <div className="title">Line 1</div>
                                                                <div
                                                                    className="text">{user.address?.line1 || "Edit to update"}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Line 2</div>
                                                                <div
                                                                    className="text">{user.address?.line2 || "Edit to update"}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="title">State</div>
                                                                <div
                                                                    className="text">{user.address?.state || "Edit to update"}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 d-flex">
                                            <div className="card profile-box flex-fill">
                                                <div className="card-body">
                                                    <h3 className="card-title">
                                                        Bank Information{" "}
                                                        <Link
                                                            to="#"
                                                            className="edit-icon"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#wallet_modal">
                                                            <i className="fa fa-pencil" />
                                                        </Link>
                                                    </h3>
                                                    
                                                    <p className="text-primary text-small">
                                                        Do you want a DTB wallet? <Link to="/orgregister">register for one here</Link>
                                                    </p>
                                                    {/* <br/> */}
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Bank Name</div>
                                                            <div className="text">{user.bank?.bankName || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Account Number</div>
                                                            <div className="text">{user.bank?.accountNumber || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Account Name</div>
                                                            <div className="text">{user.bank?.accountName || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Bank Branch</div>
                                                            <div className="text">{user.bank?.bankBranch || "Edit to update"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">M-Pesa Number</div>
                                                            <div className="text">{user.bank?.mpesa || "Edit to update"}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        </div>
                                    </div>
                                    ))}
                                </div>
                                )}
                            </div>

                            <CompanyProfileForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyProfile;

import React, {useContext, useEffect, useState} from 'react';
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import {Link, useParams, useLocation} from "react-router-dom";
import {UserContext} from "../../../../InitialPage/App.js";
import {Applogo} from "../../../../Entryfile/imagepath.js";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase.js";
import html2pdf from 'html2pdf.js';
import {format, isValid} from "date-fns";
import {toast, ToastContainer, Slide} from "react-toastify";
import { getAuth } from "firebase/auth";

const auth = getAuth();
const MusterRoll = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const selectedCompanyId = queryParams.get('companyId');
    const selectedMonthYear = queryParams.get('monthYear');

    const [varianceData, setVarianceData] = useState([]);
    const [menu, setMenu] = useState(false);
    const [employeeData, setEmployeeData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [kraPinNo, setkraPinNo] = useState(null);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    // const formattedYear = format(selectedMonthYear, 'MMMM-yyyy'); //changed from selected date
    if (selectedMonthYear && isValid(selectedMonthYear)) {
        const formattedYear = format(selectedMonthYear, 'MMMM-yyyy');
        console.log(formattedYear);
    } else {
        console.error('Invalid Date:', selectedMonthYear);
    }


    const formattedMonth = format(selectedDate, 'MMMM');
    useEffect(() => {
        const fetchEmployeeSalaries = async () => {
            try {
                // setLoading(true);

                const currentMonth = new Date().getMonth() + 1; // Get current month (1-indexed)
                const currentYear = new Date().getFullYear(); // Get current year
                const currentMonthString = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`; // Format current month as 'YYYY-MM'

                // const salariesQuery = query(collection(db, 'add-salary'), where('month', '==', currentMonthString));
                let salariesQuery;
                let salaryDocs;

                if (selectedMonthYear) {
                    salariesQuery = query(collection(db, 'add-salary'), where('month', '==', selectedMonthYear));

                    if (userData && userData[0]?.role !== 'sys-admin') {
                        salaryDocs = await getDocs(query(salariesQuery, where('organisationId', '==', userData[0]?.organisationId)));
                    } else {
                        salaryDocs = await getDocs(query(salariesQuery, where('organisationId', '==', parseInt(selectedCompanyId))));
                    }
                } else{
                    salariesQuery = query(collection(db, 'add-salary'), where('month', '==', currentMonthString));

                    if (userData && userData[0]?.role !== 'sys-admin') {
                        salaryDocs = await getDocs(query(salariesQuery, where('organisationId', '==', userData[0]?.organisationId)));
                    } else {
                        salaryDocs = await getDocs(query(salariesQuery, where('organisationId', '==', parseInt(selectedCompanyId))));
                    }
                }

                const employeeDataArray = [];

                for (const doc of salaryDocs.docs) {
                    const employeeSalaryData = doc.data();
                    const employeeName = employeeSalaryData.employeeName;

                    // Fetch additional employee data from 'add-employee' collection
                    // const employeeQuery = query(collection(db, 'add-employee'), where('employeeName', '==', employeeName));
                    // const employeeDocs = await getDocs(employeeQuery);

                    // if (!employeeDocs.empty) {
                        // const employeeData = employeeDocs.docs[0].data();
                        // employeeData.email = employeeDocs.docs[0].data().email;
                        // console.log(`Email fetched for employeeName: ${employeeData.email}`);
                        // handleEmailSend(employeeData.email);
                        const combinedData = {
                            ...employeeSalaryData,
                            // role: employeeData.role,
                            // location: employeeData.location
                        };

                        // Fetch additional department data from 'departments' collection
                        const departmentQuery = query(collection(db, 'departments'), where('employeeName', '==', employeeName));
                        const departmentDocs = await getDocs(departmentQuery);

                        if (!departmentDocs.empty) {
                            const departmentData = departmentDocs.docs[0].data();
                            combinedData.departmentName = departmentData.departmentName;
                            // Add other fields from 'departments' collection as needed
                        }

                        employeeDataArray.push(combinedData);
                    // }
                }

                setEmployeeData(employeeDataArray);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching employee salaries:', error);
                setLoading(false);
            }
        };

        fetchEmployeeSalaries();
    }, [userData, loading]);

    const [logo, setLogo] = useState([]);
    
    useEffect(() => {
        const fetchLogo = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const logoSnapshot = await getDocs(
                    query(collection(db, 'add-company'), where('organisationId', '==', userData[0]?.organisationId))
                );
                const logoData = logoSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setLogo(logoData);
            }
        };

        fetchLogo();
    }, [userData]);

    useEffect(() => {
        const fetchPayrollData = async () => {
            try {
                const colRef = collection(db, 'add-salary');

                let payrollQuerySnapshot;

                if (userData && userData[0]?.role === 'sys-admin') {
                    const adminQuery = query(colRef, where('organisationId', '==', parseInt(selectedCompanyId)));
                    payrollQuerySnapshot = await getDocs(adminQuery);
                } else {
                    const userQuery = query(colRef, where('organisationId', '==', userData[0]?.organisationId));
                    payrollQuerySnapshot = await getDocs(userQuery);
                }
    
                const payrollData = payrollQuerySnapshot.docs.map(doc => doc.data());
                // console.log("Fetched payroll data:", payrollData);
    
                const selectedYear = selectedDate.getFullYear();
                const selectedMonth = selectedDate.getMonth() + 1; // Note: January is 0 in JavaScript
    
                const currentMonthData = payrollData.filter(data => {
                    if (data.month) {
                        const [year, month] = data.month.split('-');
                        return parseInt(year) === selectedYear && parseInt(month) === selectedMonth;
                    }
                    return false; // Exclude data without a defined month
                });
    
                const previousMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
                const previousYear = selectedMonth === 1 ? selectedYear - 1 : selectedYear;
    
                const previousMonthData = payrollData.filter(data => {
                    if (data.month) {
                        const [year, month] = data.month.split('-');
                        return parseInt(year) === previousYear && parseInt(month) === previousMonth;
                    }
                    return false; // Exclude data without a defined month
                });
    
                // console.log("Current month data:", currentMonthData);
                // console.log("Previous month data:", previousMonthData);
    
                const calculateTotal = (data, key) => data.reduce((total, item) => {
                    if (item[key] !== undefined) {
                        const value = parseFloat(item[key]);
                        if (!isNaN(value)) {
                            return total + value;
                        } else {
                            console.error(`Invalid ${key} value:`, item[key]);
                            return total; // Exclude invalid values from the total
                        }
                    } else {
                        console.error(`Missing ${key} value in data:`, item);
                        return total; // Exclude missing values from the total
                    }
                }, 0);
    
                const currentMonthTotal = {
                    paye: calculateTotal(currentMonthData, 'payePayable'),
                    nssf: calculateTotal(currentMonthData, 'nssfContribution'),
                    nhif: calculateTotal(currentMonthData, 'nhifContribution'),
                    netpay: calculateTotal(currentMonthData, 'netPay')
                };
    
                // console.log("Current Month Total:", currentMonthTotal);
    
                const previousMonthTotal = {
                    paye: calculateTotal(previousMonthData, 'payePayable'),
                    nssf: calculateTotal(previousMonthData, 'nssfContribution'),
                    nhif: calculateTotal(previousMonthData, 'nhifContribution'),
                    netpay: calculateTotal(previousMonthData, 'netPay')
                };
    
                // console.log("Previous Month Total:", previousMonthTotal);
    
                const varianceData = [
                    { y: "PAYE", "Previous Month": previousMonthTotal.paye.toFixed(2), "Current Month": currentMonthTotal.paye.toFixed(2) },
                    { y: "NSSF", "Previous Month": previousMonthTotal.nssf.toFixed(2), "Current Month": currentMonthTotal.nssf.toFixed(2) },
                    { y: "NHIF", "Previous Month": previousMonthTotal.nhif.toFixed(2), "Current Month": currentMonthTotal.nhif.toFixed(2) },
                    { y: "Net Pay", "Previous Month": previousMonthTotal.netpay.toFixed(2), "Current Month": currentMonthTotal.netpay.toFixed(2) },
                    { y: "Employee Count", "Previous Month": previousMonthData.length, "Current Month": currentMonthData.length }
                ];
    
                // console.log("Variance Data:", varianceData);
    
                setVarianceData(varianceData);
    
            } catch (error) {
                console.error("Error fetching payroll data:", error);
            }
        };
    
        fetchPayrollData();
    }, [selectedDate, userData]);
    

    const handlePDFExport = () => {
        const element = document.getElementById('muster-roll'); // Assuming you have a container with id 'payslip-container'

        html2pdf()
            .from(element)
            .set({
                pagebreak: { mode: 'avoid-all' }, // Prevent page breaks within content
                filename: 'muster-roll.pdf',
                margin: [0, 0],
                jsPDF: { format: 'a3', orientation: 'landscape' }, // Set PDF page size and orientation
                html2canvas: { scale: 3 } // Adjust scaling if needed
            })
            .save();
    };

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('muster-roll').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const generateCSV = () => {
        const csvRows = [];

        // Header row
        const header = [
            'Employee Name',
            'Qualification',
            'Department',
            'Location',
            'Currency',
            'Basic Salary (Kshs)',
            'Total Cash Pay',
            'Total Non Cash Pay',
            'Total Gross Pay',
            'NSSF Contribution',
            'Chargeable Pay',
            'Tax Charged',
            'Personal Relief',
            'Insurance Relief',
            'PAYE Tax (J-K) (Kshs)',
            'NHIF Contribution',
            'Total Deductions',
            'Net Salary'
        ];
        csvRows.push(header.join(','));

        // Data rows
        employeeData.forEach(employeeData => {
            const row = [
                employeeData.employeeName,
                employeeData.role,
                employeeData.departmentName,
                employeeData.location,
                employeeData.currency,
                employeeData.basicSalary || '',
                employeeData.totalCashPay || '',
                employeeData.totalNonCashPay || '',
                employeeData.totalGrossPay || '',
                employeeData.nssfContribution || '',
                employeeData.taxablePay || '',
                employeeData.taxPayable || '',
                employeeData.personalRelief || '',
                employeeData.insuranceRelief || '',
                employeeData.payePayable || '',
                employeeData.nhifContribution || '',
                employeeData.totalDeductions || '',
                employeeData.netPay || ''
            ];
            csvRows.push(row.join(','));
        });

        // Join rows with newline character
        const csvString = csvRows.join('\n');

        // Create a Blob object and initiate download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'muster-roll.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleEmailSend = () => { //(recipientEmail)
        const musterRollContainer = document.getElementById('muster-roll');
        
        if (!musterRollContainer) {
            console.error('Muster Roll container not found.');
            showToast('Error: Muster Roll container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(musterRollContainer)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "Muster roll for " + format(new Date(), 'MMMM yyyy'),
                        Body: "Please find attached the Muster Roll for this month.",
                        Attachments: [
                            {
                                name: "muster-roll.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('Muster Roll sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending Muster Roll via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Muster Roll</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`/finance/payroll/downloads`}>Downloads</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Muster Roll</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                {/* <button className="btn btn-white" onClick={handleEmailSend}><i
                                                    className="fa fa-mail-bulk fa-lg"/> Email
                                                </button> */}
                                                <button className="btn btn-white" onClick={generateCSV}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                <button className="btn btn-white" onClick={handlePDFExport}><i
                                                    className="fa fa-file-pdf fa-lg"/> PDF
                                                </button>
                                                <button className="btn btn-white" onClick={handlePrint}><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" id="muster-roll">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Muster Roll</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        {logo[0]?.logoURL ? (
                                                            <img src={logo[0].logoURL} className="inv-logo" alt="Company Logo"/>
                                                        ) : (
                                                            <img src={Applogo} className="inv-logo" alt=""/>
                                                        )}
                                                        {/* <img src={Applogo} className="inv-logo" alt=""/> */}
                                                        {/* <p>Employer's Name: <strong>******</strong></p> */}
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">Muster Roll</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table_wrapper">
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    {/* <th></th> */}
                                                                    {/* <th>Account</th> */}
                                                                    <th>Currency</th>
                                                                    <th>
                                                                        Basic <br/>
                                                                        Salary
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Cash <br/>
                                                                        Earnings <br/>
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Non <br/>
                                                                        Cash <br/>
                                                                        Benefits <br/>
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Gross <br/>
                                                                        Pay
                                                                    </th>
                                                                    <th>
                                                                        NSSF <br/>
                                                                        Contribution

                                                                    </th>
                                                                    <th>
                                                                        Taxable <br/>
                                                                        Earnings
                                                                    </th>
                                                                    <th>
                                                                        Tax <br/>
                                                                        Calculation- <br/>
                                                                        Normal
                                                                    </th>
                                                                    <th>Personal<br />
                                                                        Relief</th>
                                                                    <th>
                                                                        Insurance <br/>
                                                                        Relief
                                                                    </th>
                                                                    <th>PAYE</th>
                                                                    <th>NHIF</th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Deductions
                                                                    </th>
                                                                    <th>
                                                                        Net <br/>
                                                                        Pay
                                                                    </th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th>Number</th>
                                                                    <th>Month</th>
                                                                    <th>Employee</th>
                                                                    {/* <th>
                                                                        Job <br/>
                                                                        Title
                                                                    </th> */}
                                                                    {/* <th>Department</th> */}
                                                                    {/* <th>Location</th> */}
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {employeeData && employeeData.map((employee, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{employee.month}</td>
                                                                        <td className="table-month">{employee.employeeName}</td>
                                                                        {/* <td>
                                                                            {employee.role}
                                                                        </td> */}
                                                                        {/* <td>{employee.departmentName}</td> */}
                                                                        {/* <td>{employee.location}</td> */}
                                                                        <td>{employee.currency}</td>
                                                                        <td>{employee.basicSalary}</td>
                                                                        <td>{employee.totalCashPay}</td>
                                                                        <td>{employee.totalNonCashPay}</td>
                                                                        <td>{employee.totalGrossPay}</td>
                                                                        <td>{employee.nssfContribution}</td>
                                                                        <td>{employee.taxablePay}</td>
                                                                        <td>{employee.taxPayable}</td>
                                                                        <td>{employee.personalRelief}</td>
                                                                        <td>{employee.insuranceRelief}</td>
                                                                        <td>{employee.payePayable}</td>
                                                                        <td>{employee.nhifContribution}</td>
                                                                        <td>{employee.totalDeductions}</td>
                                                                        <td>{employee.netPay}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="table-bordered-footer">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <div className="table_wrapper">
                                                            <h3 className="text-uppercase">VARIANCE SUMMARY</h3>
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>Account</th>
                                                                    <th>
                                                                        First <br/>
                                                                        period <br/>
                                                                    </th>
                                                                    <th>
                                                                        Second <br/>
                                                                        period <br/>
                                                                    </th>
                                                                    <th>Variance</th>
                                                                    <th>
                                                                        Percentage(%)<br/>
                                                                        Variance
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {varianceData.map((data, index) => {
                                                                        // Calculate variance and percentage variance
                                                                        const variance = (data["Current Month"] - data["Previous Month"]).toFixed(2);
                                                                        // Calculate percentage variance with a check for division by zero
                                                                        const percentageVariance = data["Previous Month"] == 0
                                                                        ? (data["Current Month"] == 0 ? 0 : "No variance") // If both current and previous values are 0, return 0%, else return 100%
                                                                        : ((variance / data["Previous Month"]) * 100).toFixed(2);
                                                                        // const percentageVariance = ((variance / data["Previous Month"]) * 100).toFixed(2);

                                                                        return (
                                                                            <tr key={index} className="text-center">
                                                                                <td className="table-month">{data.y}</td>
                                                                                <td>{data["Previous Month"]}</td>
                                                                                <td>{data["Current Month"]}</td>
                                                                                <td>{variance}</td>
                                                                                <td>{percentageVariance}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default MusterRoll;
